<template>
  <v-list-item class="list-item-chips d-flex">
    <div class="d-flex my-auto flex-wrap pt-2">
      <v-chip
        v-for="(item, index) in items"
        :key="index"
        small
        class="chip mr-2 mb-2"
        color="primary"
        text-color="white"
        >{{ item }}</v-chip
      >
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItemChips',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
    &:last-child
        margin-right: 0
</style>
